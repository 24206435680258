import axios from 'axios'
import {MarketItemModel, BuyModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_MARKETPLACE_ITEMS_URL = `${API_URL}/api/marketplace/`
export const GET_MARKETPLACE_BUY_URL = `${API_URL}/api/marketplace/buy`


export function get_marketplace() {
  return axios.get<MarketItemModel[]>(GET_MARKETPLACE_ITEMS_URL)
}

export function buy_app(uuid: string) {
  return axios.post<BuyModel>(GET_MARKETPLACE_BUY_URL, {
    uuid: uuid,
  })
}
