import axios from 'axios'
import {AppModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_APPS_URL = `${API_URL}/api/app/`


export function get_apps() {
  return axios.get<AppModel[]>(GET_APPS_URL)
}
