import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {get_marketplace} from '../../modules/marketplace/_requests'
import {MarketItemModel} from '../../modules/marketplace/_models'
import {MarketItemWidget} from '../../modules/marketplace/MarketItemWidget'

const Marketplace = () => {
  let [items, setItems] = useState([] as MarketItemModel[])
  useEffect(() => {
    const requestApps = async () => {
      try {
        const {data} = await get_marketplace();
        setItems(data);
      } catch (error) {
        console.log(error);
      }
    }
    requestApps();
  }, []);

  return <>

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      {items.map(item => {
        return <div className='col-xl-4'>
          <MarketItemWidget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='body-white'
            iconColor='primary'
            title={item.title}
            description={item.description}
            price={item.price / 10000}
            titleColor='gray-900'
            descriptionColor='gray-400'
            uuid={item.uuid}
          />
        </div>
      })}
    </div>
    {/* end::Row */}

  </>
}

const MarketplaceWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.APPS'})}</PageTitle>
      <Marketplace />
    </>
  )
}

export {MarketplaceWrapper}
