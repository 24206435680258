import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Device1} from "../../modules/IoT/Device1";
import {useEffect, useState} from 'react'
import {AppModel} from '../../modules/dashboard/_models'
import {get_apps} from '../../modules/dashboard/_requests'
import {AppWidget} from '../../modules/dashboard/AppWidget'

const DashboardPage = () => {
  let [apps, setApps] = useState([] as AppModel[])
  useEffect(() => {
    const requestApps = async () => {
      try {
        const {data} = await get_apps();
        setApps(data);
      } catch (error) {
        console.log(error);
      }
    }
    requestApps();
  }, []);

  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {apps.map(app => {
        return <div className='col-xl-4'>
          <AppWidget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='body-white'
            iconColor='primary'
            title={app.title}
            description={app.description}
            titleColor='gray-900'
            descriptionColor='gray-400'
            uuid={app.uuid}/>
        </div>
      })}
    </div>
    {/* end::Row */}
  </>;
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MARKETPLACE'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
