/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {buy_app} from './_requests'
import {useNavigate} from 'react-router-dom'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  price: number,
  uuid: string,
}

const MarketItemWidget: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  price,
  uuid
}) => {
  const navigate = useNavigate();
  async function clickHandler(event: any) {
    const resp = await buy_app(uuid);
    if (resp.status == 202) {
      navigate("/dashboard");
    } else {
      console.log(resp.statusText);
      console.log(resp.data.message);
    }
  }
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>

        <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        <div className="ml-auto mt-2 btn btn-success" onClick={clickHandler}>Buy ${price}</div>
      </div>
    </a>
  )
}

export {MarketItemWidget}
